import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import VueLazyload from 'vue-lazyload'
import 'element-plus/dist/index.css'
createApp(App).use(store).use(router).use(ElementPlus, { locale: zhCn }).use(VueLazyload, {
  loading: '/imgs/image/avatar/tx.png',
  error: '/imgs/image/avatar/tx.png'
}).mount('#app')
