<template>
  <div class="login-container">
    <div class="login-left">
      <img :src="imgUrl" alt="" />
    </div>
    <div class="login-right">
      <div class="login-box">
        <div class="code" @click="changeFlag" style="cursor: pointer;">
          <img v-if="!flag" :src="path + 'imgs/image/login/7.png'" alt="" />
          <img v-else :src="path + 'imgs/image/login/22.png'" alt="" />
        </div>
        <div v-if="!flag">
          <div class="login-title">
            <div @click="changeTab(1)" :class="{ current: loginTab }" style="cursor: pointer;">
              登录/注册
            </div>
            <span>|</span>
            <div @click="changeTab(0)" :class="{ current: !loginTab }" style="cursor: pointer;">
              密码登录
            </div>
          </div>
          <div v-if="loginTab">
            <div class="input">
              <el-input
                    v-model="mobile"
                    placeholder="请输入手机号"
                  >
                    <template #prepend>
                      <el-select
                        v-model="countrycodes"
                        placeholder="区号"
                        style="width: 85px"
                        :fit-input-width="true"
                      >
                        <el-option :label="item.code"  v-for="item in Countrycodes" :key="item.id" :value="item.code" />
                      </el-select>
                    </template>

                  </el-input>
            </div>
            <div class="getcode">
              <div class="inputCode">
                <input type="text" placeholder="短信验证码" v-model="yzm"/>
              </div>
              <div class="btnCode" @click="Send">
                <button :disabled="btn">{{ m<60?`重新获取(${m})`:'获取验证码' }}</button>
              </div>
            </div>
          </div>
          <div v-if="!loginTab">
            <div class="mobile-login">
              <input
                style="width: 100%"
                placeholder="请输入手机号码"
                v-model="mobile"
              />
            </div>
            <div class="password">
              <input
                style="width: 100%"
                placeholder="请输入密码"
                v-model="password"
                type="password"
              />
            </div>
          </div>
          <div class="login"  @click="logins(loginTab)">
            <button>
              {{ loginTab ? "登录/注册" : "登录" }}
            </button>
          </div>
          <div class="checkbox">
            <input type="checkbox" v-model="agree"/>
            <span>我已阅读并同意</span>
            <span
              style="color: #15b6f0; cursor: pointer"
              @click="
                $router.push({ path: 'loginpolicy', query: { id: item.id } })
              "
              v-for="item in notice"
              :key="item.id"
              >《{{ item.title }}》</span
            >
          </div>
          <div class="forget" v-show="!loginTab" @click="$router.push('/forgot')">
            <span>忘记密码?</span>
          </div>
        </div>
        <div class="saoCode" v-show="flag">
          <h3>扫码登录</h3>
          <div class="QRcode">
            <img :src="xcxQrcode" alt="">
          </div>
          <div class="openwx">
            <div class="openleft">
              <img :src="path + 'imgs/image/login/55.png'" alt="" />
            </div>
            <div class="openright">
              <p>打开微信"扫一扫"</p>
              <p>扫描二维码快速登录</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import '@/assets/css/login/login.scss'
import { ref, onMounted } from 'vue'
import { HomeBnner, login, countryCode, send, mobilelogin, creatQrcode, polling } from '@/api/login'
import { ElMessage } from 'element-plus'
import { isMobile, isPassword } from '@/utils/regexp'
import { useRouter } from 'vue-router'
import store from '@/store'
const url = ref(localStorage.getItem('url'))
const loginTab = ref(1)
const flag = ref(false)
const path = ref(localStorage.getItem('path')).value
const mobile = ref('') // 手机号
const password = ref('') // 密码
const agree = ref(false)// 是否同意
const yzm = ref('')
const Countrycodes = ref(null)// 各国区号
const countrycodes = ref('+86')// 获取各国区号
const router = useRouter()
let timer = ref(null)
const m = ref(60)// 秒
const btn = ref(false)
// 主页图片
const imgUrl = ref(null)
// 用户告知
const notice = ref(null)
const xcxQrcode = ref(null)// 小程序二维码
let lx = null
const scenes = ref('')
const xcxcode = async () => {
  const res = await creatQrcode()
  const { scene, xcxqrcode } = res.data
  xcxQrcode.value = xcxqrcode
  scenes.value = scene
}
const changeFlag = async () => {
  flag.value = !flag.value
  if (flag.value) {
    lx = setInterval(async () => {
      const res1 = await polling(scenes.value)
      if (res1.data.code === 1) {
        ElMessage({
          message: res1.data.msg,
          type: 'success'
        })
        flag.value = false
        clearInterval(lx)
        const { token, fillin, username, avatar, alias, identity, hyfl_id,stage } = res1.data?.data
        store.commit('user/setUserInfo', { token, fillin, username, avatar, alias, identity, hyfl_id,stage })
        router.push('/')
      }
      if (!flag.value) {
        clearInterval(lx)
      }
    }, 2000)
  }
}
const changeTab = (index) => {
  loginTab.value = index
}
// 登录
const logins = async (i) => {
  if (i === 0) {
    if (!isMobile(mobile.value)) {
      ElMessage({
        message: '手机号格式不正确',
        type: 'warning'
      })
      return
    }
    if (!isPassword(password.value)) {
      ElMessage({
        message: '密码6-30位，不能包含空格',
        type: 'warning'
      })
      return
    }
    if (agree.value) {
      const res = await login(mobile.value, password.value)
      if (!res) return
      if (res.data.code === 1) {
        ElMessage({
          message: res.data.msg,
          type: 'success'
        })
        const { token, fillin, username, avatar, alias, identity, hyfl_id,stage } = res.data?.data.userinfo
        store.commit('user/setUserInfo', { token, fillin, username, avatar, alias, identity, hyfl_id,stage })
        router.push('/')
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'error'
        })
      }
    } else {
      ElMessage({
        message: '阅读用户协议',
        type: 'warning'
      })
    }
  }
  if (i === 1) {
    if (!isMobile(mobile.value)) {
      return ElMessage({
        message: '手机号格式不正确',
        type: 'warning'
      })
    }
    if (yzm.value === '') {
      return ElMessage({
        message: '请输入验证码',
        type: 'warning'
      })
    }
    if (agree.value) {
      const res = await mobilelogin(mobile.value, yzm.value)
      if (res.data.code === 1) {
        const { token, fillin, username, avatar, alias, identity, hyfl_id,stage } = res.data.data.userinfo
        store.commit('user/setUserInfo', { token, fillin, username, avatar, alias, identity, hyfl_id,stage })
        router.push('/')
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'error'
        })
      }
    } else {
      ElMessage({
        message: '阅读用户协议',
        type: 'warning'
      })
    }
  }
}
// 各国区号
const zone = async () => {
  const res = await countryCode()
  Countrycodes.value = res.data.data
}
const Send = async () => {
  if (!isMobile(mobile.value)) {
    ElMessage({
      message: '手机号格式不正确',
      type: 'warning'
    })
    return
  }
  btn.value = true
  clearInterval(timer)
  timer = setInterval(() => {
    m.value--
    if (m.value <= 0) {
      m.value = 60
      clearInterval(timer)
      btn.value = false
    }
  }, 1000)
  await send(countrycodes.value.replace(/\+/g, ''), mobile.value)
}
onMounted(() => {
// 获取主页图片
  HomeBnner().then((res) => {
    imgUrl.value = url.value + res.data.data.loginimg.img_url
    notice.value = res.data.data.notice
  })
  zone()
  xcxcode()
})

</script>

<style scoped>
</style>
